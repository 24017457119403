// src/components/CookieConsentBanner.js
import React from "react";
import { Link } from "react-router-dom";

const CookieConsentBanner = ({ onPreferenceChange }) => {
  const handleAccept = () => {
    onPreferenceChange("accepted");
  };

  const handleDecline = () => {
    onPreferenceChange("declined");
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-zinc-800 text-white p-4">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
        <p className="mr-4 mb-2 sm:mb-0 text-center sm:text-left">
          We use cookies to enhance your browsing experience and analyze site
          traffic. By clicking "Accept", you consent to our use of cookies.
          <Link to="/privacy-policy" className="underline ml-1">
            Learn more
          </Link>
        </p>
        <div className="flex">
          <button
            onClick={handleAccept}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Accept
          </button>
          <button
            onClick={handleDecline}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentBanner;

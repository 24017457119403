import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-warmGray-900 text-warmGray-300 p-4 sticky top-0 z-50 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <Link
          to="/"
          className="text-blue-300 text-xl font-bold hover:text-blue-200"
        >
          Chams Toure
        </Link>
        {/* Mobile menu button */}
        <button
          className="md:hidden text-warmGray-300 hover:text-blue-300 focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
            {isMenuOpen ? (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
              />
            ) : (
              <path
                fillRule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            )}
          </svg>
        </button>
        {/* Desktop menu */}
        <ul className="hidden md:flex space-x-6">
          <NavItem href="#hero" text="Home" />
          <NavItem href="#contact" text="Contact" isButton={true} />
        </ul>
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden mt-4">
          <ul className="flex flex-col space-y-4">
            <NavItem
              href="#hero"
              text="Home"
              onClick={() => setIsMenuOpen(false)}
            />
            <NavItem
              href="#contact"
              text="Contact"
              isButton={true}
              onClick={() => setIsMenuOpen(false)}
            />
          </ul>
        </div>
      )}
    </nav>
  );
};

const NavItem = ({ href, text, isButton = false, onClick }) => {
  const baseClasses = "transition duration-300 ease-in-out";
  const regularClasses = "hover:text-blue-300";
  const buttonClasses =
    "bg-blue-400 hover:bg-blue-500 text-warmGray-900 px-4 py-2 rounded-md";

  return (
    <li>
      <a
        href={href}
        className={`${baseClasses} ${
          isButton ? buttonClasses : regularClasses
        }`}
        onClick={onClick}
      >
        {text}
      </a>
    </li>
  );
};

export default Navbar;

import React from "react";
import ProfilePicture from "../img/profile_picture.png";

const Hero = () => {
  const experiences = [
    {
      title: "Full-Stack Developer / Machine Learning Engineer",
      company: "Self-employed",
      period: "APR 2023 - JUL 2024",
      projects: [
        {
          name: "Athena AI - SaaS (www.athenaai.me)",
          description:
            "Developed a personalized learning platform for exploring new subject areas:",
          highlights: [
            "Designed and implemented frontend and backend",
            "Integrated Large Language Models for learning material creation",
            "Implemented scalable microservices architecture",
            "Developed AI assistants for contextual interaction",
            "Implemented adaptive learning algorithm",
            "Integrated monetization features",
          ],
        },
      ],
    },
    {
      title: "Full-Stack Developer / Data Engineer",
      company: "Mediabrands GmbH/Kinesso",
      period: "AUG 2021 - JUL 2024",
      projects: [
        {
          name: "Central CRM System",
          description: "Unified 6 data sources in a central software.",
          highlights: [
            "Improved data accessibility through robust data pipeline",
            "Developed REST APIs as interfaces between data sources",
          ],
        },
        {
          name: "Data Warehouse",
          description: "Improved data integrity and decision-making.",
          highlights: [
            "Integrated data from 100+ different sources",
            "Implemented ETL pipelines to optimize data processing",
          ],
        },
        {
          name: "Marketing Campaign Forecasting Tool",
          description: "Solution to optimize campaign planning and workflow.",
          highlights: [
            "Developed a web application for managing advertising campaigns",
            "Implemented comprehensive end-to-end tests",
            "Optimized deployment process through containerization",
          ],
        },
        {
          name: "Campaign Management System",
          description:
            "Central tool for creating and managing advertising campaigns.",
          highlights: [
            "Developed web application to replace 3 separate tools",
            "Optimized internal processes for campaign management",
          ],
        },
        {
          name: "FAQ Application",
          description:
            "FAQ platform to support the campaign management system.",
          highlights: [
            "Connected with the existing ticket system",
            "Integrated with the campaign management system",
          ],
        },
      ],
    },
  ];

  const skills = [
    {
      category: "Frontend Development",
      description:
        "Proficient in creating responsive and intuitive user interfaces using modern frameworks and libraries",
    },
    {
      category: "Backend Development",
      description:
        "Experienced in designing and implementing robust server-side applications and APIs",
    },
    {
      category: "AI and Machine Learning",
      description:
        "Knowledgeable in applying various ML techniques and working with large language models",
    },
    {
      category: "DevOps",
      description:
        "Familiar with containerization, CI/CD pipelines, and cloud services for efficient deployment and scaling",
    },
    {
      category: "Data Engineering",
      description:
        "Skilled in designing and implementing data pipelines and warehouses for improved data accessibility and integrity",
    },
  ];

  return (
    <section id="hero" className="bg-warmGray-900 p-8 md:p-20">
      <div className="container mx-auto max-w-4xl">
        <div className="flex flex-col md:flex-row items-center mb-12 bg-warmGray-800 p-6 rounded-lg shadow-lg">
          <img
            src={ProfilePicture}
            alt="Profile"
            className="rounded-full w-32 h-32 md:w-40 md:h-40 mb-4 md:mb-0 md:mr-8 shadow-lg border-4 border-blue-400"
          />
          <div className="text-center md:text-left">
            <h1 className="text-4xl font-bold mb-2 text-blue-300">
              Chams Touré
            </h1>
            <p className="text-xl p-3 rounded-lg bg-warmGray-700 mb-4 shadow-md inline-block text-warmGray-300">
              Software Engineer
            </p>
            <div className="flex flex-wrap justify-center md:justify-start gap-4">
              <a
                href="mailto:chams.toure@gmail.com"
                className="text-blue-300 hover:text-blue-200 flex items-center"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                </svg>
                chams.toure@gmail.com
              </a>
            </div>
          </div>
        </div>

        <div className="mb-12 bg-warmGray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-blue-300">About Me</h2>
          <p className="text-warmGray-300 leading-relaxed">
            My journey in software development has been driven by a passion for
            solving complex problems and a keen interest in emerging
            technologies. Over the years, I've had the opportunity to work on
            diverse projects, from building scalable web applications to
            exploring the frontiers of machine learning. I'm particularly
            fascinated by the potential of AI to transform how we learn and
            process information, which led me to develop Athena AI. Outside of
            coding, I enjoy staying active as a basketball coach, which has
            taught me valuable lessons in teamwork and leadership that I apply
            to my development projects.
          </p>
        </div>

        <div className="mb-12 bg-warmGray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-blue-300">
            Skills Overview
          </h2>
          <div className="grid gap-4 md:grid-cols-2">
            {skills.map((skill, index) => (
              <div
                key={index}
                className="bg-warmGray-700 p-4 rounded-lg shadow-md"
              >
                <h4 className="font-semibold mb-2 text-blue-300">
                  {skill.category}
                </h4>
                <p className="text-warmGray-300 text-sm">{skill.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-warmGray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-blue-300">
            Work Experience
          </h2>
          {experiences.map((exp, index) => (
            <div key={index} className="mb-8 last:mb-0">
              <h3 className="text-xl font-semibold text-blue-300">
                {exp.title}
              </h3>
              <p className="text-warmGray-300">{exp.company}</p>
              <p className="text-warmGray-400 mb-4">{exp.period}</p>
              <div className="grid gap-4 md:grid-cols-2">
                {exp.projects.map((project, projectIndex) => (
                  <div
                    key={projectIndex}
                    className="bg-warmGray-700 p-4 rounded-lg shadow-md"
                  >
                    <h4 className="font-semibold mb-2 text-blue-300">
                      {project.name}
                    </h4>
                    <p className="text-warmGray-300 mb-2 text-sm">
                      {project.description}
                    </p>
                    <ul className="list-disc list-inside mb-2">
                      {project.highlights.map((highlight, highlightIndex) => (
                        <li
                          key={highlightIndex}
                          className="text-xs text-warmGray-400"
                        >
                          {highlight}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;

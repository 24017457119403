import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import PastWork from "./components/PastWork";
import Footer from "./components/Footer";
import Impressum from "./components/Impressum";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookieConsentBanner from "./components/CookieConsentBanner";

function App() {
  const [cookiePreference, setCookiePreference] = useState(null);

  useEffect(() => {
    const preference = localStorage.getItem("cookiePreference");
    setCookiePreference(preference || null);
  }, []);

  const handleCookiePreferenceChange = (newPreference) => {
    setCookiePreference(newPreference);
    localStorage.setItem("cookiePreference", newPreference);
    if (newPreference === "accepted") {
      // Initialize analytics or other cookie-dependent services
      console.log("Cookies accepted, initializing services...");
    } else {
      // Disable non-essential cookies and services
      console.log("Cookies declined, disabling non-essential services...");
    }
  };

  return (
    <Router>
      <div className="bg-zinc-dark text-white min-h-screen">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                {/* <PastWork /> */}
              </>
            }
          />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
        {cookiePreference === null && (
          <CookieConsentBanner
            onPreferenceChange={handleCookiePreferenceChange}
          />
        )}
      </div>
    </Router>
  );
}

export default App;

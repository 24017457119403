import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="contact" className="bg-zinc-dark p-6 text-center">
      <p>
        Contact me at{" "}
        <a
          href="mailto:your-email@example.com"
          className="text-blue-400 hover:text-blue-600"
        >
          chams.toure@gmail.com
        </a>
      </p>
      <div className="mt-4 flex justify-center space-x-4">
        <Link to="/impressum" className="text-blue-400 hover:text-blue-600">
          Imprint
        </Link>
        <Link
          to="/privacy-policy"
          className="text-blue-400 hover:text-blue-600"
        >
          Privacy Policy
        </Link>
      </div>
      <p className="mt-4">&copy; 2024 Chams Touré. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
